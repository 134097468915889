<script>
import { mapActions } from 'vuex';

export default {
  props: ['hash'],
  data: () => ({
    form: {
      password: '',
      rePassword: '',
    },

    showPass: false,
    loading: false,
  }),

  methods: {
    ...mapActions('auth', ['login']),

    async submit() {
      this.loading = true;

      try {
        if (this.form.password !== this.form.rePassword) {
          return this.$toastr.error('Senhas estão diferentes');
        }
        const { password } = this.form;
        const hash = this.hash;
        await this.$store.dispatch('auth/resetPassword', { hash, password });
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    clear() {
      this.form = {
        password: '',
        rePassword: '',
      };
      this.$refs.form.reset();
    },
  },
};
</script>

<template>
  <div class="px-5 px-sm-12">
    <div>
      <h1 class="text-center">Redefina sua senha</h1>

      <v-form ref="form" class="mt-10" @submit.prevent="submit">
        <v-text-field v-model="form.password" label="Senha" type="password" outlined persistent-placeholder />
        <v-text-field
          v-model="form.rePassword"
          label="Confirme sua senha"
          type="password"
          outlined
          persistent-placeholder
        />
        <div class="text-center">
          <v-btn type="submit" x-large block color="primary" :loading="loading"> Redefinir senha </v-btn>
        </div>
      </v-form>

      <div class="mt-10 text-center">
        <router-link to="/login">Voltar para o login</router-link>
      </div>
    </div>
  </div>
</template>
